<template lang="pug">
.flex.flex-col.overflow-y-auto.w-full
  p.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ $t("notifications.connexionLost") }}
  .flex.mt-3.mb-10.items-center
    .flex.flex-col.relative
      p.text-annotationColor.font-SourceSansPro.text-base.font-semibold {{ $t("notifications.lossDuration") }}
      .flex.items-center.mt-2
        skif-input-number(
          :disabled='isAllowedCreate && role !== "EDITOR"',
          v-model.number='notification.lostsignal_notless',
          :error='validate'
        )
        p.text-annotationColor.font-SourceSansPro.text-xs.font-semibold.ml-3 {{ $t("notifications.min") }}
      transition(name='el-zoom-in-top')
        .text-notify.text-xs.top-1.left-0.absolute.mt-16(v-if='validate') {{ $t("notifications.lostMsg") }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['notification', 'isLostSignalNotLessMissing'],
  computed: {
    validate: {
      get() {
        return this.isLostSignalNotLessMissing
      },
      set(newValue) {
        console.log('validation', newValue)
        this.$emit('updateLostSignalNotLess', newValue)
      }
    },
    ...mapGetters('login', ['role', 'disabledRole']),
    isAllowedCreate() {
      return this.disabledRole && this.role !== 'OPERATOR'
    }
  },
  watch: {
    'notification.lostsignal_notless': function (val) {
      this.validate = val === ''
    }
  }
}
</script>
